

/*==============================
	FONTS
==============================*/

$font-system-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
$font-body: 'Figtree', sans-serif;
$font-headings: 'Bely Display W00 Regular', sans-serif;

/*==============================
	COLORS
==============================*/

$color-base: #6d6d6d;
$color-brand: #1e305e;
$color-secondary: #f03f23;
$color-light: #ffffff;
$color-dark: #292A2D;


/*==============================
	SIZES
==============================*/

$max-width: 1510px;
$grid-spacing: 2.25vw;


/*==============================
	DEFAULTS
==============================*/

$default-document-link-color: $color-brand;
$img-loc : "/images/local";
$nav-horizontal-burger-threshold: 600px;


/* COMPONENT VARIABLES */

/*==============================
	FORMS
==============================*/

$form-active-color: #6b9aeb;
$form-active-text: #ffffff;

$form-item-standard-size: 54px;
$form-item-xl-size: 54px;
$form-item-border-color: #cccccc;
$form-item-border-width: 1px;
$form-item-border-radius: 0px;
$form-item-bg: #ffffff;
$form-item-fg: #2a2a2a;
$form-disabled-item-border-color: #e3e3e3;
$form-disabled-item-bg: #ebebeb;
$form-disabled-item-fg: lighten($form-item-fg, 10%);
$form-item-focus-shadow: rgba(0, 0, 0, .12);
$form-icon-color: #cccccc;

/*==============================
	TABS
==============================*/

$tab-background: transparent; //pozadí záložky
$tab-text: #878787; //text záložky
$tab-selected-background: transparent; //pozadí aktivní záložky
$tab-selected-text: $color-secondary; //text aktivní záložky


/*==============================
	BUTTONS
==============================*/

$button-radius: 0;
$button-bg: $color-brand;
$button-fg: $color-light;
$button-primary-bg: $color-secondary;
$button-primary-fg: $color-light;
$button-primary-bg-hover: $color-brand;
$button-primary-fg-hover: $color-light;

/*==============================
	CALENDAR -PAGE
==============================*/

$cal-page-type-bg: #373737;
$cal-page-type-color: #ffffff;
$cal-page-type-selected-bg: $color-brand;
$cal-page-type-selected-color: #ffffff;

$cal-page-head-color: #373737;

$cal-page-day-bg: #f8f8f8;
$cal-page-day-color:#242424;
$cal-page-day-border-color:#c7c7c7;

$cal-page-day-in-prev-month-bg:#e7e7e7;
$cal-page-day-in-prev-month-color:#979797;

$cal-page-day-in-next-month-bg:$cal-page-day-in-prev-month-bg;
$cal-page-day-in-next-month-color:$cal-page-day-in-prev-month-color;

$cal-page-day-selected-border: $cal-page-type-selected-bg;
$cal-page-day-selected-color: $cal-page-type-selected-bg;

$cal-page-day-today-border:$color-secondary;
$cal-page-day-today-color:$color-secondary;
