.org-contacts{
	&__contact{
		margin-left: 1rem;
		display: inline-block;
		span::before{
			content: "";
			width: 0.5rem;
			height: 0.5rem;
			display: inline-block;
			vertical-align: middle;
			margin: 0 0.75rem 0 0;
			background-color: #ef0021;
	  }
	}
	&__item{
		padding-left: 0;
		margin-bottom: 0;
		&:not([class*="draggableArea"]){
			min-height: 45px;
		}
	}
	&__thumbnail{
		display: none;
	}
}


@include min(600px) {
	.-page-header + .blender__container{
		display: block;
		padding-bottom: 230px;
		.blender{
			background: $color-dark url(/images/local/blender/v-202410170829/0_str.jpg) no-repeat 50% 100% / cover;
		}
	}
}


@include min(1024px){
	.header{
		&__heading-container{
			padding: 0.5rem 1.5rem 0rem 0;
		}
		&__logo{
			max-width: 185px;
		}
	}
}
