.tiles {
	&__list {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: 3rem 0 1rem;
		list-style-type: none;
		@include grid(500px 2 2%, 1024px 3);
		.-nosidebar & {
			@include grid(500px 2 2%, 1024px 4);
		}
	}
}

.tile {
	&__item {
		width: 100%;
		font-size: rem(22px);
		font-family: $font-headings;
		font-weight: 600;
	}


	&__link {
		position: relative;
		padding: 1.25rem 1rem;
		@include flex(df, aic, jcc);
		text-align: center;
		height: 100%;
		transition: all .3s ease-in-out;

		@include link(&) {
			border: 2px solid $color-secondary;
			color: $color-secondary;
			text-decoration: none;
			//border-radius: 5px;
		}

		@include link-over(&) {
			background-color: $color-secondary;
			color: $color-light;
		}
	}
}
