
.tags {
	list-style-type: none;
	padding-left: 0;

	&__item {
		display: inline-block;
		vertical-align: top;
		margin: 0 .35em .35em 0;
	}

	&__link {
		text-decoration: none;
		display: inline-block;
		border-radius: 30px;
		padding: 6px 12px;
	}
}

@include link(".tags__link") {
	color: $color-light;
	text-decoration: none;
	background: {
		color: $color-brand;
		repeat: repeat-x;
		size: auto 200%;
		position: 0 0;
	}
}

@include link-over(".tags__link") {
	background-color: $color-secondary;
	color: $color-light;
}
