
$filter-id: 28 30 31 32 33 41;  //id typu akce NUTNÉ DODRŽET STEJNÉ POŘADÍ S IKONAMI
$event-ico-color: $color-base $cal-page-type-color $cal-page-type-selected-color #fff; // barvy icon ve :: vypisu eventu, ve filtru, ve filtru-selected, v top eventy

@for $i from 1 through length($event-ico-color) {
    $event-ico: (
        '<svg xmlns="http://www.w3.org/2000/svg" width="11.644" height="11.41" viewBox="0 0 11.644 11.41"><path id="Path_476" data-name="Path 476" d="M550.951,272.3l1.374,2.856a.55.55,0,0,0,.413.308l3.073.458a.565.565,0,0,1,.3.958l-2.224,2.223a.57.57,0,0,0-.157.5l.524,3.139a.55.55,0,0,1-.794.592l-2.749-1.482a.536.536,0,0,0-.511,0l-2.749,1.482a.55.55,0,0,1-.794-.592l.524-3.139a.57.57,0,0,0-.157-.5l-2.224-2.223a.565.565,0,0,1,.3-.958l3.073-.458a.55.55,0,0,0,.413-.308l1.375-2.856A.542.542,0,0,1,550.951,272.3Z" transform="translate(-544.638 -271.984)" fill="#{nth($event-ico-color, $i)}"/></svg>'
        '<svg xmlns="http://www.w3.org/2000/svg" width="11.238" height="11.248" viewBox="0 0 11.238 11.248" fill="#{nth($event-ico-color, $i)}"><path id="Path_477" data-name="Path 477" d="M564.564,274.771a5.567,5.567,0,0,1,6.855,0,4.259,4.259,0,0,1-6.855,0Z" transform="translate(-562.373 -273.59)"/><path id="Path_478" data-name="Path 478" d="M571.419,282.654a5.6,5.6,0,0,1-6.855,0,4.244,4.244,0,0,1,6.855,0Z" transform="translate(-562.373 -272.576)"/><path id="Path_479" data-name="Path 479" d="M573.877,278.987a5.575,5.575,0,0,1-1.451,3.762,5.259,5.259,0,0,0-8.335,0,5.6,5.6,0,0,1,0-7.525,5.259,5.259,0,0,0,8.335,0A5.575,5.575,0,0,1,573.877,278.987Z" transform="translate(-562.639 -273.363)"/></svg>'
        '<svg xmlns="http://www.w3.org/2000/svg" width="13" height="11.942" viewBox="0 0 13 11.942"><path id="Path_483" data-name="Path 483" d="M633.093,279.927v-1.763a.552.552,0,0,0-.552-.552h-1.3a2.007,2.007,0,0,0-.27-4,2.007,2.007,0,0,0-.27,4h-4.373a2.8,2.8,0,1,0-.705,0h-2.175a.552.552,0,0,0-.552.552v5.266a.552.552,0,0,0,.552.552h9.1a.552.552,0,0,0,.552-.552v-1.763l2.8,1.775v-5.289Zm-2.962-4.3a.835.835,0,1,1,.835.835A.838.838,0,0,1,630.131,275.625Zm-5.783-.788a1.628,1.628,0,1,1,1.622,1.622A1.625,1.625,0,0,1,624.347,274.837Z" transform="translate(-622.89 -272.04)" fill="#{nth($event-ico-color, $i)}"/></svg>'
        '<svg xmlns="http://www.w3.org/2000/svg" width="11.72" height="11.946" viewBox="0 0 11.72 11.946"><path id="Path_480" data-name="Path 480" d="M588.6,272.79a7.278,7.278,0,0,1-10.7,0,11.772,11.772,0,0,0-.51,3.476c0,4.665,2.627,8.47,5.854,8.47s5.866-3.8,5.866-8.47A11.716,11.716,0,0,0,588.6,272.79Zm-8.991,4.79c0-.544.668-.985,1.495-.985s1.483.442,1.483.985-.668.974-1.483.974S579.609,278.123,579.609,277.58Zm3.646,5.662c-1.891,0-3.431-1.2-3.431-2.684a1.927,1.927,0,0,1,.113-.668,4.675,4.675,0,0,0,6.636,0,1.927,1.927,0,0,1,.113.668C586.687,282.041,585.158,283.242,583.256,283.242Zm2.14-4.688c-.827,0-1.495-.43-1.495-.974s.668-.985,1.495-.985,1.495.442,1.495.985S586.222,278.554,585.4,278.554Z" transform="translate(-577.39 -272.79)" fill="#{nth($event-ico-color, $i)}"/></svg>'
        '<svg xmlns="http://www.w3.org/2000/svg" width="11.213" height="16.084" viewBox="0 0 11.213 16.084"><path id="Path_482" data-name="Path 482" d="M616.893,276.895h-.913c0-2.145-2.021-3.905-3.117-4.7v11.2a.657.657,0,0,1,.012.154,3.65,3.65,0,1,1-3.591-3.032,3.892,3.892,0,0,1,2.666,1V270.51l.682.385C612.8,271,616.893,273.4,616.893,276.895Z" transform="translate(-605.68 -270.51)" fill="#{nth($event-ico-color, $i)}"/></svg>'
        '<svg xmlns="http://www.w3.org/2000/svg" width="9.041" height="17" viewBox="0 0 9.041 17"><path id="Path_481" data-name="Path 481" d="M599.158,281.927a3.1,3.1,0,0,1,1.325,1.952h-1.994c0,1.152-.21,3.538-1.889,4.27l-.38-.949c1.05-.447,1.286-2.223,1.286-3.321h-2.047a3.169,3.169,0,0,1,1.312-1.966,5.41,5.41,0,0,1-3.32-5.287c0-3.023,2.021-5.477,4.514-5.477s4.527,2.454,4.527,5.477A5.42,5.42,0,0,1,599.158,281.927Z" transform="translate(-593.45 -271.15)" fill="#{nth($event-ico-color, $i)}"/></svg>'
    );
    @for $j from 1 through length($filter-id) {
        //.events .-type-id-#{nth($filter-id, $j)},
        .-type-id-#{nth($filter-id, $j)}{
            font-size: 0;
            padding: 0;
        }

        @if $i == 1 {   //event-types in regulat text
            .-type-id-#{nth($filter-id, $j)}:not(.-before)::after,
            .-type-id-#{nth($filter-id, $j)}.-before::before{
                content: encode-svg(nth($event-ico, $j));
            }
        }
        @if $i == 2 {   //filtr
            .code-list-items .-type-id-#{nth($filter-id, $j)}::after{
                content: encode-svg(nth($event-ico, $j));
            }
        }
        @if $i == 3 {   //filtr-selected
            .-selected.-type-id-#{nth($filter-id, $j)}::after{
                content: encode-svg(nth($event-ico, $j));
            }
        }
        @if $i == 4 {   //top-events
            .events .-type-id-#{nth($filter-id, $j)}::after{
                content: encode-svg(nth($event-ico, $j));
            }
        }
    }
}

.article__body.-calendar{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
    "top-events"
    "calendar"
    "events";
    grid-gap: 0 2rem;
    gap: 0 2rem;
    @include min(1100px){
        grid-template-columns: 1fr 300px;
        grid-template-areas: 
        "top-events top-events"
        "events calendar"
        "links-gal links-gal";
        .calendar-sticky.-sticked{
            position: fixed;
            top: 20px;
        }
    }
    @include min(1280px){
        grid-template-columns: 1fr 320px;
    }
    > .dev-element-block:first-of-type{
        grid-area: top-events;
        padding-bottom: 2rem;
    }
    > .dev-element-block:nth-of-type(2){
        grid-area: events;
    }
    > .dev-element-block:last-of-type{
        grid-area: links-gal;
    }
}

.code-list-items{
    &__list{
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 -.25rem;
        list-style-type: none;
    }
}

.calendar-type{
    &__item{
        padding: 0 .25rem .5rem;
        @include link(){
            text-decoration: none;
        }
        @include link-over(){
            text-decoration: underline;
        }
    }
    &__link{
        display: inline-flex;
        padding: 0.25rem 0.75rem 0.25rem;
        line-height: 1;
        align-items: center;
        justify-content: center;
        background-color: $cal-page-type-bg;
        border-radius: 6px;
        min-height: 2em;
        font-size: rem(14px);
        &::after{
            position: relative;
            top: 1px;
            display: flex;
            margin-right: 0rem;
            justify-content: center;
            margin-left: .5rem;
        }
        @include link(&){
            color: $cal-page-type-color;
        }
        &.-selected{
            background-color: $cal-page-type-selected-bg;
            @include link(&){
                color: $cal-page-type-selected-color;
            }
        }
    }
}

.calendar{
    font-size: rem(14px);
    &__container{
        position: relative;
        grid-area: calendar;
    }
    td,
    th,
    tr,
    tr:nth-child(odd){
        border-bottom: 0;
        background-color: transparent;
    }
    td,
    th{
        text-align: center;
        min-width: 0;
        padding: 0.35em 0.15em;
    }
    th{
        padding: 1em 0.35em 0.35em;
        color: $cal-page-head-color;
        font-size: rem(17px);
        font-weight: 700;
    }
    [role="button"]{
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
        &:focus-visible{
			@extend %default-outline;
		}
    }
    [tabindex="-1"]{
        cursor: not-allowed;
    }

    .monthSelector{
        background-color: #EEEEEE!important;
        &__month{
            font-size: rem(17px);
            text-transform: uppercase;
            font-weight: 700;
        }
        &__link{
            span{
                font-size: 0;
                &::before{
                    display: inline-block;
                    vertical-align: middle;
                    content: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" width="7" height="9" viewBox="0 0 7 9"><path id="Polygon_2" data-name="Polygon 2" d="M4.5,0,9,7H0Z" transform="translate(7) rotate(90)" fill="#9d9d9d"/></svg>');
                }
            }
            &.-prev{
                span::before{
                    transform: rotate(180deg);
                }
            }
            &.-next{
                
            }
        }
        &__link,
        &__month{
            padding-top: 1em;
            padding-bottom: 1em;
        }
    }
    &__day{
        background-color: $cal-page-day-bg;
        color: $cal-page-day-color;
        border: 0;
        > span{
            display: block;
            min-height: 3em;
            padding: 0.3em 0.75em 0.5em;
            border: 2px solid transparent;
            border-radius: 10px;
        }
        &[role="button"]{
            font-weight: 700;
            > span:after{
                content: "";
                display: block;
                width: .25rem;
                height: .25rem;
                border-radius: 50%;
                background-color: $color-base;
                margin: 0 auto;
            }
        }
        &.-dayInPrevMonth{
            > span{
                color: $cal-page-day-in-prev-month-color!important;
                &:after{
                    background-color: $cal-page-day-in-prev-month-color!important;
                }
            }
        }

        &.-dayInMonth{

        }

        &.-dayInNextMonth{
            > span{
                color: $cal-page-day-in-next-month-color!important;
                &:after{
                    background-color: $cal-page-day-in-next-month-color!important;
                }
            }
        }

        &.-today{
            font-weight: 700;
            > span{
                border-color: $cal-page-day-today-border;
                color: $cal-page-day-today-color;
                &:after{
                    background-color: $cal-page-day-today-color;
                }
            }
        }

        &.-selected{
            > span{
                color: $cal-page-day-selected-color;
                border-color: $cal-page-day-selected-border;
                &:after{
                    background-color: $cal-page-day-selected-color;
                }
            }
        }
    }
}